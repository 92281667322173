import { langs, defaultLangKey } from '../../content/languages'

export default (path) => {
  const pathLangKey = path.substring(0, 3)

  let langKey = defaultLangKey
  for (const l of langs) {
    if (pathLangKey === '/' + l) {
      langKey = l
    }
  }

  return langKey
}
