import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Button from '../Button'
import generateLink from '../../utils/generateLink'
import Logo from '../logo'
import './header.css'


class HeaderMenu extends React.Component {
  constructor (prosp) {
    super(prosp)

    this.state = {
      burgerOpen: false
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleMenuBurgerClick = this.handleMenuBurgerClick.bind(this)
  }

  handleToggleMenu () {
    this.setState({ burgerOpen: !this.state.burgerOpen })
  }

  handleMenuBurgerClick () {
    this.setState({ burgerOpen: false })
  }

  render () {
    const { links, common, langKey, location } = this.props
    return (
      <header className='bg-white-gefa font-semibold'>
        <div className='block lg:flex menu flex-row-reverse lg:flex-row items-start lg:items-center justify-end text-green-gefa'>
          <input type='checkbox' id='tm' className='tm' checked={this.state.burgerOpen} />
          <div className='h-20 px-6 flex items-center lg:hidden float-left' style={{ width: 170 }}>
            <Link to={generateLink(langKey, '/')}>
              <Logo />
            </Link>
          </div>
          <label htmlFor='tm' id='toggle-menu' className='h-20 px-6 flex items-center lg:hidden float-right'>
            <div className='menu-toggle flex justify-end items-center' onClick={this.handleToggleMenu} ><Button text='Menu' /></div>
          </label>
          <div className='pl-6  hidden lg:inline-block' style={{ width: 170 }}>
            <Link title='home' to={generateLink(langKey, '/')}>
              <Logo />
            </Link>
          </div>
          <ul className='mt-20 main-menu cf font-bold py-1 flex-grow flex items-center justify-center flex-col lg:flex-row lg:items-center h-20'>
            {
              links.map((el, i) => <li key={`menu_link_${i}`} className={`${i === 0 && 'mt-20 lg:mt-0 '}mx-3`} onClick={this.handleMenuBurgerClick}><Link className={`inline-block ${el.onlySmall ? 'lg:hidden' : 'lg:inline'} whitespace-no-wrap font-bold text-lg cursor-pointer ${(location.pathname.startsWith(el.path)) ? 'force-underline' : ''}`} title={el.name} to={el.path}>{el.name}</Link></li>)
            }
          </ul>
          <span className='h-20' />
          <div className='pr-6 py-1 hidden lg:inline-block'>
            <Link title={common.contact_us[langKey]} to={generateLink(langKey, '/contacts')} className='no-underline'>
              <Button text={common.contact_us[langKey]} additionalClasses='uppercase text-lg' />
            </Link>
          </div>
          <div className='clear-both' />
        </div>
      </header>
    )
  }
}

const Header = ({ langKey, location }) => {
  const { site: { siteMetadata: { pagesData: { common } } } } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
          pagesData {
            common {
              applications {
                it, en, de
              }
              company {
                it, en, de
              }
              contact {
                it, en, de
              }
              contact_us {
                it, en, de
              }
              services {
                it, en, de
              }
              quality {
                it, en, de
              }
            }
          }
        }
      }
    }
      `
  )

  const links = [
    {
      path: generateLink(langKey, '/company'),
      name: common.company[langKey]
    },
    {
      path: generateLink(langKey, '/services'),
      name: common.services[langKey]
    },
    {
      path: generateLink(langKey, '/applications'),
      name: common.applications[langKey]
    },
    {
      path: generateLink(langKey, '/quality'),
      name: common.quality[langKey]
    },
    {
      path: generateLink(langKey, '/contacts'),
      name: common.contact[langKey],
      onlySmall: true
    }
  ]

  return (<HeaderMenu links={links} common={common} langKey={langKey} location={location} />)
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
