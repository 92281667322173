import React from 'react'
import Image from '../image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import './PreFooter.css'
import generateLink from '../../utils/generateLink'

const PreFooter = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query PreFooterQuery {
      site {
        siteMetadata {
          pagesData {
            services {
              mainSection {
                secondSection {
                  points {
                    title {
                      en
                      de
                      it
                    }
                    id
                    imageLink
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    points
  } = data.site.siteMetadata.pagesData.services.mainSection.secondSection
  console.log(points)
  return (
    <div className='w-full relative md:mt-32'>
      {
        points.map((el, i) =>
          <div key={`prefooter_el_${i}`} className='inline-block w-full md:w-1/3 align-bottom relative overflow-hidden'>
            <Link to={`${generateLink(langKey, '/services')}#${el.id}`} title={el.title[langKey]}>
              <span className='prefooter-link'>
                <Image className='h-full' imgRef={el.imageLink} />
              </span>
              <span className='pointer-events-none absolute bottom-0 left-0 p-6 md:text-xl text-bold text-white-gefa'>
                <h3 className='underline text-2xl'>{el.title[langKey]}</h3>
              </span>
            </Link>
          </div>
        )
      }
    </div>
  )
}

export default PreFooter
