import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const altNams = {
  placeholderImage: {
    it: 'placeholderImage',
    en: 'placeholderImage',
    de: 'placeholderImage'
  },
  indexFirstBlockImage: {
    it: 'macchina plastic matal',
    en: 'plastic metal machines',
    de: 'indexFirstBlockImage'
  },
  professional_cleaning_1: {
    it: 'professional_cleaning_1',
    en: 'professional_cleaning_1',
    de: 'professional_cleaning_1'
  },
  professional_cleaning_2: {
    it: 'professional_cleaning_2',
    en: 'professional_cleaning_2',
    de: 'professional_cleaning_2'
  },
  floatting_floor: {
    it: 'floatting_floor',
    en: 'floatting_floor',
    de: 'floatting_floor'
  },
  professional_ovens: {
    it: 'professional_ovens',
    en: 'professional_ovens',
    de: 'professional_ovens'
  },
  fitness: {
    it: 'fitness',
    en: 'fitness',
    de: 'fitness'
  },
  sci_and_treking: {
    it: 'sci_and_treking',
    en: 'sci_and_treking',
    de: 'sci_and_treking'
  },
  treking: {
    it: 'treking',
    en: 'treking',
    de: 'treking'
  },
  furniture_1: {
    it: 'furniture_1',
    en: 'furniture_1',
    de: 'furniture_1'
  },
  furniture_2: {
    it: 'furniture_2',
    en: 'furniture_2',
    de: 'furniture_2'
  },
  suitsace_accessory_1: {
    it: 'suitsace_accessory_1',
    en: 'suitsace_accessory_1',
    de: 'suitsace_accessory_1'
  },
  suitsace_accessory_2: {
    it: 'suitsace_accessory_2',
    en: 'suitsace_accessory_2',
    de: 'suitsace_accessory_2'
  },
  white_goods: {
    it: 'white_goods',
    en: 'white_goods',
    de: 'white_goods'
  },
  gates_handling: {
    it: 'gates_handling',
    en: 'gates_handling',
    de: 'gates_handling'
  },
  assemblaggio_mocio_1: {
    it: 'assemblaggio_mocio_1',
    en: 'assemblaggio_mocio_1',
    de: 'assemblaggio_mocio_1'
  },
  assemblaggio_mocio_2: {
    it: 'assemblaggio_mocio_2',
    en: 'assemblaggio_mocio_2',
    de: 'assemblaggio_mocio_2'
  },
  assemblaggio_mocio_3: {
    it: 'assemblaggio_mocio_3',
    en: 'assemblaggio_mocio_3',
    de: 'assemblaggio_mocio_3'
  },
  assemblaggio_mocio_4: {
    it: 'assemblaggio_mocio_4',
    en: 'assemblaggio_mocio_4',
    de: 'assemblaggio_mocio_4'
  },
  assemblaggio_scopettone_1: {
    it: 'assemblaggio_scopettone_1',
    en: 'assemblaggio_scopettone_1',
    de: 'assemblaggio_scopettone_1'
  },
  assemblaggio_scopettone_2: {
    it: 'assemblaggio_scopettone_2',
    en: 'assemblaggio_scopettone_2',
    de: 'assemblaggio_scopettone_2'
  },
  assemblaggio_scopettone_3: {
    it: 'assemblaggio_scopettone_3',
    en: 'assemblaggio_scopettone_3',
    de: 'assemblaggio_scopettone_3'
  },
  coperchio_bicomponent: {
    it: 'coperchio_bicomponent',
    en: 'coperchio_bicomponent',
    de: 'coperchio_bicomponent'
  },
  coperchio_bicomponent_2: {
    it: 'coperchio_bicomponent_2',
    en: 'coperchio_bicomponent_2',
    de: 'coperchio_bicomponent_2'
  },
  coperchio_bicomponent_3: {
    it: 'coperchio_bicomponent_3',
    en: 'coperchio_bicomponent_3',
    de: 'coperchio_bicomponent_3'
  },
  linea_1: {
    it: 'linea_1',
    en: 'linea_1',
    de: 'linea_1'
  },
  linea_2: {
    it: 'linea_2',
    en: 'linea_2',
    de: 'linea_2'
  },
  linea_3: {
    it: 'linea_3',
    en: 'linea_3',
    de: 'linea_3'
  },
  linea_4: {
    it: 'linea_4',
    en: 'linea_4',
    de: 'linea_4'
  },
  macchina_1: {
    it: 'macchina_1',
    en: 'macchina_1',
    de: 'macchina_1'
  },
  macchina_2: {
    it: 'macchina_2',
    en: 'macchina_2',
    de: 'macchina_2'
  },
  macchina_3: {
    it: 'macchina_3',
    en: 'macchina_3',
    de: 'macchina_3'
  },
  macchina_4: {
    it: 'macchina_4',
    en: 'macchina_4',
    de: 'macchina_4'
  },
  macchina_5: {
    it: 'macchina_5',
    en: 'macchina_5',
    de: 'macchina_5'
  },
  macchina_6: {
    it: 'macchina_6',
    en: 'macchina_6',
    de: 'macchina_6'
  },
  magazzino_material: {
    it: 'magazzino_material',
    en: 'magazzino_material',
    de: 'magazzino_material'
  },
  magazzino_stampi: {
    it: 'magazzino_stampi',
    en: 'magazzino_stampi',
    de: 'magazzino_stampi'
  },
  manopola_1: {
    it: 'manopola_1',
    en: 'manopola_1',
    de: 'manopola_1'
  },
  manopola_2: {
    it: 'manopola_2',
    en: 'manopola_2',
    de: 'manopola_2'
  },
  manopola_4: {
    it: 'manopola_4',
    en: 'manopola_4',
    de: 'manopola_4'
  },
  manopola_6: {
    it: 'manopola_6',
    en: 'manopola_6',
    de: 'manopola_6'
  },
  maopola_3: {
    it: 'maopola_3',
    en: 'maopola_3',
    de: 'maopola_3'
  },
  maopola_5: {
    it: 'maopola_5',
    en: 'maopola_5',
    de: 'maopola_5'
  },
  mocio_1: {
    it: 'mocio_1',
    en: 'mocio_1',
    de: 'mocio_1'
  },
  mocio_2: {
    it: 'mocio_2',
    en: 'mocio_2',
    de: 'mocio_2'
  },
  porta_scopa_bicomponente: {
    it: 'porta_scopa_bicomponente',
    en: 'porta_scopa_bicomponente',
    de: 'porta_scopa_bicomponente'
  },
  ruota: {
    it: 'ruota',
    en: 'ruota',
    de: 'ruota'
  },
  ruota_bicomponente_4: {
    it: 'ruota_bicomponente_4',
    en: 'ruota_bicomponente_4',
    de: 'ruota_bicomponente_4'
  },
  ruote_bicomponente_1: {
    it: 'ruote_bicomponente_1',
    en: 'ruote_bicomponente_1',
    de: 'ruote_bicomponente_1'
  },
  ruote_bicomponente_2: {
    it: 'ruote_bicomponente_2',
    en: 'ruote_bicomponente_2',
    de: 'ruote_bicomponente_2'
  },
  ruote_bicomponente_3: {
    it: 'ruote_bicomponente_3',
    en: 'ruote_bicomponente_3',
    de: 'ruote_bicomponente_3'
  },
  ruote_bimcomponente_4: {
    it: 'ruote_bimcomponente_4',
    en: 'ruote_bimcomponente_4',
    de: 'ruote_bimcomponente_4'
  },
  tira_acqua: {
    it: 'tira_acqua',
    en: 'tira_acqua',
    de: 'tira_acqua'
  },
  peek_black: {
    it: 'peek_black',
    en: 'peek_black',
    de: 'peek_black'
  },
  consulting: {
    it: 'consulting',
    en: 'consulting',
    de: 'consulting'
  },
  tira_acqua_link: {
    it: 'esempio air inkected component - tira acqua',
    en: 'example air inkected component',
    de: 'example air inkected component'
  },
  peek_link: {
    it: 'peek_link',
    en: 'peek_link',
    de: 'peek_link'
  },
  peek_black_link: {
    it: 'polimero in granuli - peek',
    en: 'granular polimer - peek',
    de: 'granular polimer - peek'
  },
  porta_scopa_bicomponente_link: {
    it: 'porta_scopa_bicomponente_link',
    en: 'porta_scopa_bicomponente_link',
    de: 'porta_scopa_bicomponente_link'
  },
  ruote_link: {
    it: 'ruote in bi-materia',
    en: 'bi-component wheels',
    de: 'bi-component wheels'
  },
  trust: {
    it: 'trust',
    en: 'trust',
    de: 'trust'
  },
  quality_control: {
    it: 'quality_control',
    en: 'quality_control',
    de: 'quality_control'
  },
  development: {
    it: 'development',
    en: 'development',
    de: 'development'
  },
  mould_1: {
    it: 'mould_1',
    en: 'mould_1',
    de: 'mould_1'
  },
  quality_inspector: {
    it: 'quality_inspector',
    en: 'quality_inspector',
    de: 'quality_inspector'
  },
  materials: {
    it: 'materials',
    en: 'materials',
    de: 'materials'
  },
  eco_friendly: {
    it: 'eco_friendly',
    en: 'eco_friendly',
    de: 'eco_friendly'
  },
  value: {
    it: 'value',
    en: 'value',
    de: 'value'
  },
  project: {
    it: 'project',
    en: 'project',
    de: 'project'
  },
  target: {
    it: 'target',
    en: 'target',
    de: 'target'
  },
  infographics: {
    it: 'infographics',
    en: 'infographics',
    de: 'infographics'
  },
  elettronics: {
    it: 'elettronics',
    en: 'elettronics',
    de: 'elettronics'
  },
  lang_it: {
    it: 'lang_it',
    en: 'lang_it',
    de: 'lang_it'
  },
  lang_en: {
    it: 'lang_en',
    en: 'lang_en',
    de: 'lang_en'
  },
  lang_de: {
    it: 'lang_de',
    en: 'lang_de',
    de: 'lang_de'
  }
}


const Image = ({ imgRef, className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      indexFirstBlockImage: file(relativePath: { eq: "foto/optimized/macchina_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      professional_cleaning_1: file(relativePath: { eq: "originals/optimized/professional_cleaning_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      professional_cleaning_2: file(relativePath: { eq: "originals/professional_cleaning_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      floatting_floor: file(relativePath: { eq: "originals/floatting_floor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      professional_ovens: file(relativePath: { eq: "originals/professional_ovens.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      fitness: file(relativePath: { eq: "originals/fitness.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sci_and_treking: file(relativePath: { eq: "originals/sci_and_treking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      treking: file(relativePath: { eq: "originals/treking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      furniture_1: file(relativePath: { eq: "originals/furniture_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      furniture_2: file(relativePath: { eq: "originals/furniture_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      suitsace_accessory_1: file(relativePath: { eq: "originals/suitsace_accessory_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      suitsace_accessory_2: file(relativePath: { eq: "originals/suitsace_accessory_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      white_goods: file(relativePath: { eq: "originals/white_goods.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gates_handling: file(relativePath: { eq: "originals/gate_handling_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

            
      assemblaggio_mocio_1: file(relativePath: { eq: "foto/assemblaggio_mocio_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_mocio_2: file(relativePath: { eq: "foto/optimized/assemblaggio-mocio-2-standard-size.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_mocio_3: file(relativePath: { eq: "foto/assemblaggio_mocio_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_mocio_4: file(relativePath: { eq: "foto/assemblaggio_mocio_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_scopettone_1: file(relativePath: { eq: "foto/assemblaggio_scopettone_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_scopettone_2: file(relativePath: { eq: "foto/optimized/assemblaggio_scopettone_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      assemblaggio_scopettone_3: file(relativePath: { eq: "foto/assemblaggio_scopettone_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      coperchio_bicomponent: file(relativePath: { eq: "foto/coperchio_bicomponent.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      coperchio_bicomponent_2: file(relativePath: { eq: "foto/coperchio_bicomponent_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      coperchio_bicomponent_3: file(relativePath: { eq: "foto/coperchio_bicomponent_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      linea_1: file(relativePath: { eq: "foto/linea_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      linea_2: file(relativePath: { eq: "foto/linea_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      linea_3: file(relativePath: { eq: "foto/linea_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      linea_4: file(relativePath: { eq: "foto/linea_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_1: file(relativePath: { eq: "foto/macchina_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_2: file(relativePath: { eq: "foto/macchina_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_3: file(relativePath: { eq: "foto/macchina_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_4: file(relativePath: { eq: "foto/macchina_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_5: file(relativePath: { eq: "foto/macchina_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      macchina_6: file(relativePath: { eq: "foto/macchina_6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      magazzino_material: file(relativePath: { eq: "foto/optimized/magazzino_material.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      magazzino_stampi: file(relativePath: { eq: "foto/magazzino_stampi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      manopola_1: file(relativePath: { eq: "foto/manopola_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      manopola_2: file(relativePath: { eq: "foto/manopola_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      manopola_4: file(relativePath: { eq: "foto/manopola_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      manopola_6: file(relativePath: { eq: "foto/manopola_6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      maopola_3: file(relativePath: { eq: "foto/maopola_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      maopola_5: file(relativePath: { eq: "foto/maopola_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      mocio_1: file(relativePath: { eq: "foto/mocio_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      mocio_2: file(relativePath: { eq: "foto/mocio_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      porta_scopa_bicomponente: file(relativePath: { eq: "foto/porta_scopa_bicomponente.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      ruota: file(relativePath: { eq: "foto/ruota.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ruota_bicomponente_4: file(relativePath: { eq: "foto/ruota_bicomponente_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      ruote_bicomponente_1: file(relativePath: { eq: "foto/ruote_bicomponente_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      ruote_bicomponente_2: file(relativePath: { eq: "foto/ruote_bicomponente_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      ruote_bicomponente_3: file(relativePath: { eq: "foto/ruote_bicomponente_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      ruote_bimcomponente_4: file(relativePath: { eq: "foto/ruote_bimcomponente_4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      tira_acqua: file(relativePath: { eq: "foto/tira_acqua.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      peek_black: file(relativePath: { eq: "originals/peek_black_resized.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      consulting: file(relativePath: { eq: "originals/consulting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      tira_acqua_link: file(relativePath: { eq: "originals/optimized/tira_acqua_link.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      peek_link: file(relativePath: { eq: "originals/optimized/peek_link.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      peek_black_link: file(relativePath: { eq: "originals/optimized/peek_black_link.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      porta_scopa_bicomponente_link: file(relativePath: { eq: "originals/optimized/porta_scopa_bicomponente_link.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ruote_link: file(relativePath: { eq: "originals/optimized/ruote_link.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      trust: file(relativePath: { eq: "originals/trust.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      quality_control: file(relativePath: { eq: "originals/quality_control.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      development: file(relativePath: { eq: "originals/development.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }


      mould_1: file(relativePath: { eq: "originals/mould_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      quality_inspector: file(relativePath: { eq: "originals/quality-inspector.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      materials: file(relativePath: { eq: "originals/materials.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eco_friendly: file(relativePath: { eq: "originals/eco_friendly.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      value: file(relativePath: { eq: "originals/value.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      project: file(relativePath: { eq: "originals/project.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      target: file(relativePath: { eq: "originals/target.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      infographics: file(relativePath: { eq: "originals/Infografica-Sostenibilita.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      elettronics: file(relativePath: { eq: "originals/elettronics.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lang_it: file(relativePath: { eq: "originals/optimized/it.png" }) {
        childImageSharp {
          fluid(maxHeight: 25) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lang_en: file(relativePath: { eq: "originals/optimized/en.png" }) {
        childImageSharp {
          fluid(maxHeight: 25) {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      lang_de: file(relativePath: { eq: "originals/optimized/de.png" }) {
        childImageSharp {
          fluid(maxHeight: 25) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img className={className} fluid={data[imgRef].childImageSharp.fluid} alt={altNams[imgRef].it} />
}

export default Image
