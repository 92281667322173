import React from 'react'
import './button.css'

export default ({ text, colorClass, invertedColorClass, additionalClasses }) => {
  return (
    <span
      className={`
        gefa-button
        border-solid border-2 border-${colorClass || 'green-gefa'} 
        cursor-pointer py-3 px-6 my-2 inline-block leading-none text-lg font-bold 
        hover:bg-${colorClass || 'green-gefa'} hover:text-${invertedColorClass || 'white-gefa'} 
        text-${colorClass || 'green-gefa'} ${additionalClasses}
      `}
    >
      {text}
    </span>
  )
}
