import React from 'react'
import './SelectLang.css'
import { Link } from 'gatsby'
import changeLanguageTolink from '../../utils/changeLanguageTolink'
import Image from '../image'

class SelectLang extends React.Component {
  onChangeLanguage (evt) {
    const newLang = evt.target.value
    console.log()
    const path = this.props.location.pathname
    const redirectPath = changeLanguageTolink(newLang, path)

    window.location.href = redirectPath
  }

  render () {
    const { curLang, title } = this.props
    const path = this.props.location.pathname
    return (
      <div>
        <div className='font-bold'>{title}</div>
        <div>
          {/*<div className='w-6 inline-block mr-2'><Image imgRef='lang_it' /></div>*/}<Link className={`hover:underline text-green-gefa font-bold ${curLang === 'it' ? 'underline' : ''}`} title='italiano' to={changeLanguageTolink('it', path)}>Italiano</Link>
        </div>
        <div>
          {/*<div className='w-6 inline-block mr-2'><Image imgRef='lang_en' /></div>*/}<Link className={`hover:underline text-green-gefa font-bold ${curLang === 'en' ? 'underline' : ''}`} title='english' to={changeLanguageTolink('en', path)}>English</Link>
        </div>
        <div>
          {/*<div className='w-6 inline-block mr-2'><Image imgRef='lang_de' /></div>*/}<Link className={`hover:underline text-green-gefa font-bold ${curLang === 'de' ? 'underline' : ''}`} title='deutsch' to={changeLanguageTolink('de', path)}>Deutsch</Link>
        </div>
        {/* <select value={curLang} onChange={this.onChangeLanguage.bind(this)} className='w-full bg-gray-gefa p-2 border-solid border-2 border-green-gefa bg-grey-gefa font-bold text-green-gefa'>
          <option className='font-bold' value='it'>Italiano</option>
          <option className='font-bold' value='en'>English</option>
          <option className='font-bold' value='de'>Deutsch</option>
        </select> */}
        
      </div>
    )
  }
}

export default SelectLang
