// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-applications-index-de-js": () => import("./../src/pages/applications/index.de.js" /* webpackChunkName: "component---src-pages-applications-index-de-js" */),
  "component---src-pages-applications-index-en-js": () => import("./../src/pages/applications/index.en.js" /* webpackChunkName: "component---src-pages-applications-index-en-js" */),
  "component---src-pages-applications-index-it-js": () => import("./../src/pages/applications/index.it.js" /* webpackChunkName: "component---src-pages-applications-index-it-js" */),
  "component---src-pages-company-de-js": () => import("./../src/pages/company.de.js" /* webpackChunkName: "component---src-pages-company-de-js" */),
  "component---src-pages-company-en-js": () => import("./../src/pages/company.en.js" /* webpackChunkName: "component---src-pages-company-en-js" */),
  "component---src-pages-company-it-js": () => import("./../src/pages/company.it.js" /* webpackChunkName: "component---src-pages-company-it-js" */),
  "component---src-pages-company-mission-de-js": () => import("./../src/pages/company/mission.de.js" /* webpackChunkName: "component---src-pages-company-mission-de-js" */),
  "component---src-pages-company-mission-en-js": () => import("./../src/pages/company/mission.en.js" /* webpackChunkName: "component---src-pages-company-mission-en-js" */),
  "component---src-pages-company-mission-it-js": () => import("./../src/pages/company/mission.it.js" /* webpackChunkName: "component---src-pages-company-mission-it-js" */),
  "component---src-pages-company-vision-de-js": () => import("./../src/pages/company/vision.de.js" /* webpackChunkName: "component---src-pages-company-vision-de-js" */),
  "component---src-pages-company-vision-en-js": () => import("./../src/pages/company/vision.en.js" /* webpackChunkName: "component---src-pages-company-vision-en-js" */),
  "component---src-pages-company-vision-it-js": () => import("./../src/pages/company/vision.it.js" /* webpackChunkName: "component---src-pages-company-vision-it-js" */),
  "component---src-pages-contacts-de-js": () => import("./../src/pages/contacts.de.js" /* webpackChunkName: "component---src-pages-contacts-de-js" */),
  "component---src-pages-contacts-en-js": () => import("./../src/pages/contacts.en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-it-js": () => import("./../src/pages/contacts.it.js" /* webpackChunkName: "component---src-pages-contacts-it-js" */),
  "component---src-pages-index-de-js": () => import("./../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-quality-de-js": () => import("./../src/pages/quality.de.js" /* webpackChunkName: "component---src-pages-quality-de-js" */),
  "component---src-pages-quality-en-js": () => import("./../src/pages/quality.en.js" /* webpackChunkName: "component---src-pages-quality-en-js" */),
  "component---src-pages-quality-it-js": () => import("./../src/pages/quality.it.js" /* webpackChunkName: "component---src-pages-quality-it-js" */),
  "component---src-pages-services-assembly-de-js": () => import("./../src/pages/services/assembly.de.js" /* webpackChunkName: "component---src-pages-services-assembly-de-js" */),
  "component---src-pages-services-assembly-en-js": () => import("./../src/pages/services/assembly.en.js" /* webpackChunkName: "component---src-pages-services-assembly-en-js" */),
  "component---src-pages-services-assembly-it-js": () => import("./../src/pages/services/assembly.it.js" /* webpackChunkName: "component---src-pages-services-assembly-it-js" */),
  "component---src-pages-services-design-de-js": () => import("./../src/pages/services/design.de.js" /* webpackChunkName: "component---src-pages-services-design-de-js" */),
  "component---src-pages-services-design-en-js": () => import("./../src/pages/services/design.en.js" /* webpackChunkName: "component---src-pages-services-design-en-js" */),
  "component---src-pages-services-design-it-js": () => import("./../src/pages/services/design.it.js" /* webpackChunkName: "component---src-pages-services-design-it-js" */),
  "component---src-pages-services-index-de-js": () => import("./../src/pages/services/index.de.js" /* webpackChunkName: "component---src-pages-services-index-de-js" */),
  "component---src-pages-services-index-en-js": () => import("./../src/pages/services/index.en.js" /* webpackChunkName: "component---src-pages-services-index-en-js" */),
  "component---src-pages-services-index-it-js": () => import("./../src/pages/services/index.it.js" /* webpackChunkName: "component---src-pages-services-index-it-js" */),
  "component---src-pages-services-materials-de-js": () => import("./../src/pages/services/materials.de.js" /* webpackChunkName: "component---src-pages-services-materials-de-js" */),
  "component---src-pages-services-materials-en-js": () => import("./../src/pages/services/materials.en.js" /* webpackChunkName: "component---src-pages-services-materials-en-js" */),
  "component---src-pages-services-materials-it-js": () => import("./../src/pages/services/materials.it.js" /* webpackChunkName: "component---src-pages-services-materials-it-js" */),
  "component---src-pages-services-mold-making-de-js": () => import("./../src/pages/services/mold_making.de.js" /* webpackChunkName: "component---src-pages-services-mold-making-de-js" */),
  "component---src-pages-services-mold-making-en-js": () => import("./../src/pages/services/mold_making.en.js" /* webpackChunkName: "component---src-pages-services-mold-making-en-js" */),
  "component---src-pages-services-mold-making-it-js": () => import("./../src/pages/services/mold_making.it.js" /* webpackChunkName: "component---src-pages-services-mold-making-it-js" */),
  "component---src-pages-services-moulding-de-js": () => import("./../src/pages/services/moulding.de.js" /* webpackChunkName: "component---src-pages-services-moulding-de-js" */),
  "component---src-pages-services-moulding-en-js": () => import("./../src/pages/services/moulding.en.js" /* webpackChunkName: "component---src-pages-services-moulding-en-js" */),
  "component---src-pages-services-moulding-it-js": () => import("./../src/pages/services/moulding.it.js" /* webpackChunkName: "component---src-pages-services-moulding-it-js" */)
}

