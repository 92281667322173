import { defaultLangKey } from '../../content/languages'
import getLangKeyFromLInk from './getLangKeyFromLInk'
import generateLink from './generateLink'

export default (newLang, path) => {
  let currentPrefix = getLangKeyFromLInk(path)
  if (currentPrefix === defaultLangKey) {
    currentPrefix = ''
  }

  const start = currentPrefix.length === 0 ? 0 : currentPrefix.length + 1

  return generateLink(newLang, path.substring(start, path.length))
}
