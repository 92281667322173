import React, { useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import generateLink from '../../utils/generateLink'
import SelectLang from '../SelectLang'


function LubendaLink ({ text, langKey, privacyPolicyURL }) {
  useEffect(() => {
    const script = document.createElement('script')

    let conf = {"enableCcpa":true,"ccpaApplies":true,"consentOnContinuedBrowsing":false,"lang":"it","siteId":1857936,"cookiePolicyId":57740412, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"bottom" }}
    if (langKey === 'en') {
      conf = {"enableCcpa":true,"ccpaApplies":true,"consentOnContinuedBrowsing":false,"lang":"en","siteId":1857936,"cookiePolicyId":60116530, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"bottom" }}
    } 
    if (langKey === 'de') {
      conf = {"enableCcpa":true,"ccpaApplies":true,"consentOnContinuedBrowsing":false,"lang":"de","siteId":1857936,"whitelabel":false,"cookiePolicyId":29478015, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"bottom" }}
    }

    global._iub = []
    global._iub.csConfiguration = conf

    script.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <a href={privacyPolicyURL} className='iubenda-white iubenda-embed' title='Privacy Policy'>{text}</a>
    </>
  )
}

const Footer = ({ langKey, location }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pagesData {
              common {
                companyName
                addressFirstRow
                addressSecondRow
                tel
                fax
                infoMail
                amministrationSite {
                  it en de
                }
                telLable {
                  it en de
                }
                faxLable {
                  it en de
                }
                mailLable {
                  it en de
                }
                home {
                  it en de
                }
                company {
                  it en de
                }
                services {
                  it en de
                }
                applications {
                  it en de
                }
                contact {
                  it en de
                }
                quality {
                  it en de
                }
                contact_us {
                  it en de
                }
              }
              footer {
                privacyRow {
                  it en de
                }
                privacyPolicy {
                  it en de
                }
                selectLanguage {
                  it en de
                }
                privacyPolicyURL {
                  it en de
                }
              }
              services {
                sideSection {
                  firstBlockLinks {
                    text { it en de }
                    path
                  }
                }
              }
            }
          }
        }
      }
  `)

  const {
    companyName,
    addressFirstRow,
    addressSecondRow,
    tel,
    fax,
    infoMail,
    telLable,
    faxLable,
    mailLable,
    home,
    company,
    services,
    applications,
    contact,
    quality
  } = site.siteMetadata.pagesData.common

  const servicesLinks = site.siteMetadata.pagesData.services.sideSection.firstBlockLinks

  const {
    privacyRow,
    privacyPolicy,
    privacyPolicyURL,
    selectLanguage
  } = site.siteMetadata.pagesData.footer

  return (
    <footer className='bg-gray-gefa pt-20 md:pt-10 pb-12 px-10 md:px-16'>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-6 m-auto'>
        <div itemScope itemType='https://schema.org/LocalBusiness'>
          <p itemProp='name' className='font-bold'>{companyName}</p>
          <p className='font-bold'>{addressFirstRow}</p>
          <p className='font-bold'>{addressSecondRow}</p>
          <p itemProp='telephone' className='font-bold text-green-gefa mt-10 md:mt-0'>
            <span className='border-solid border-2 md:border-0 border-green-gefa flex md:inline justify-center p-2 my-2 md:p-0 md:my-0'>
              <span className='font-bold'>{telLable[langKey]} {tel}</span>
            </span>
          </p>
          <p itemProp='faxNumber' className='font-bold text-green-gefa'>
            <span className='border-solid border-2 md:border-0 border-green-gefa flex md:inline justify-center p-2 my-2 md:p-0 md:my-0'>
              <span className='font-bold'>{faxLable[langKey]} {fax}</span>
            </span>
          </p>
          <p itemProp='email'>
            <a className='font-bold text-green-gefa' title='mail' href={`mailto:${infoMail}`}>
              <span className='border-solid border-2 md:border-0 border-green-gefa flex md:inline justify-center p-2 my-2 md:p-0 md:my-0'>
                <span className='font-bold'>{mailLable[langKey]} {infoMail}</span>
              </span>
            </a>
          </p>
        </div>
        <div>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={home[langKey]} to={generateLink(langKey, '/')}>{home[langKey]}</Link></p>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={company[langKey]} to={generateLink(langKey, '/company')}>{company[langKey]}</Link></p>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={services[langKey]} to={generateLink(langKey, '/services')}>{services[langKey]}</Link></p>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={applications[langKey]} to={generateLink(langKey, '/applications')}>{applications[langKey]}</Link></p>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={quality[langKey]} to={generateLink(langKey, '/quality')}>{quality[langKey]}</Link></p>
          <p className='border-solid border-b border-green-gefa md:border-none py-1 md:py-0 text-green-gefa'><Link className='font-bold' title={contact[langKey]} to={generateLink(langKey, '/contacts')}>{contact[langKey]}</Link></p>
        </div>
        <div className='hidden md:block'>
          {
            servicesLinks.map((el, i) => <p key={`services_link_${i}`} className='text-green-gefa'><Link className='font-bold' title={el.text[langKey]} to={generateLink(langKey, '/services/' + el.path)}>{el.text[langKey]}</Link></p>)
          }
        </div>
        <div className='py-10 md:py-0'>
          <SelectLang curLang={langKey} location={location} title={selectLanguage[langKey]} />
        </div>
      </div>
      <div className='mt-32 m-auto'>{privacyRow[langKey]} <LubendaLink text={privacyPolicy[langKey]} privacyPolicyURL={privacyPolicyURL[langKey]} langKey={langKey} /></div>
    </footer>
  )
}

export default Footer
